import { lazy } from "react";
import Suspense from "../Suspense";
import useSettings from "../hooks/useSettings";

const layouts = {
  layout1: lazy(() => import("./Layout1/Layout1")),
  layout2: lazy(() => import("./Layout2/Layout2")),
};

const Layout = (props) => {
  const { settings } = useSettings();
  const Layout = layouts[settings.activeLayout];

  return (
    <Suspense>
      <Layout {...props} />
    </Suspense>
  );
};

export default Layout;  
