import { lazy } from "react";
import Loadable from "../components/Loadable";
const Login = Loadable(lazy(() => import("../pages/Login")));
const LoginNet = Loadable(lazy(() => import("../pages/LoginNet")));
const Register = Loadable(lazy(() => import("../pages/Register")));
const RegisterNet = Loadable(lazy(() => import("../pages/RegisterNet")));
const RegisterLearn = Loadable(lazy(() => import("../pages/RegisterLearn")));
const ForgotPassword = Loadable(lazy(() => import("../pages/ForgotPassword")));
const ForgotPasswordNet = Loadable(lazy(() => import("../pages/ForgotPasswordNet")));



const LoginRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/login/routing-visualizer", element: <LoginNet /> },
  { path: "/signup", element: <Register /> },
  { path: "/signup/routing-visualizer", element: <RegisterNet /> },
  { path: "/signup-learn", element: <RegisterLearn /> },
  { path: "/forgot-password", element: <ForgotPassword /> }, 
  { path: "/forgot-password/routing-visualizer", element: <ForgotPasswordNet /> }, 

  
  
];

export default LoginRoutes;


