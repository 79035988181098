import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import { SettingsProviderIde } from "./contexts/SettingsContextIde";
import { SettingsProviderAdmin } from "./contexts/SettingsContextAdmin";
import { SettingsProvider } from "./contexts/SettingsContext";
import { AppTheme } from "./components";
import { useRoutes } from "react-router-dom";
import { AuthContext } from './contexts/auth';
import { useAuth } from './contexts/auth/functions';
import allRoutes from './routes/MainRoutes';
import './App.css';

const store = createStore(rootReducer, composeWithDevTools());


const App = () => {


  const { token, register, login, logout, userData } = useAuth();

  const mainRoutes = useRoutes(allRoutes)


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <SettingsProviderAdmin>
            <SettingsProvider>
            <SettingsProviderIde>
            


              <AppTheme>


                <AuthContext.Provider
                  value={{
                    isLoggedIn: !!token,
                    token: token,
                    userData: userData,
                    register: register,
                    login: login,
                    logout: logout
                  }}
                >

                  {mainRoutes}
                </AuthContext.Provider>


              </AppTheme>


              </SettingsProviderIde>
            </SettingsProvider>

        </SettingsProviderAdmin>
      </Provider>
    </LocalizationProvider>
  );
};

export default App;


// NOTES
// 1. CHECK  src\contexts\theme\themeOptions.jsx
