import DashboardLayout from "../contexts/layouts/Layout";
import Redirect from "./Redirect";
import LoginRoutes from "./LoginRoutes";
import NoDashboardRoutes from "./NoDashboardRoutes";
import AdminRoutes from "./AdminRoutes";

import CustomerRoutes from "./CustomerRoutes";
import LearningRoutes from "./LearningRoutes";




const MainRoutes = [
  {
    
    element: (
      <DashboardLayout /> 
    ),

    children: [
      ...CustomerRoutes,
    ],
  },

  ...NoDashboardRoutes,
  ...AdminRoutes,
  ...LearningRoutes,
  ...LoginRoutes,
  { path: "/", element: <Redirect /> },
];

export default MainRoutes;
