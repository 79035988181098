import AuthGuardAdmin from "./AuthGuardAdmin";
import DashboardLayout from "../contexts/layouts/Admin/LayoutAdmin";
import AdminRoutes2 from "./AdminRoutes2";



const AdminRoutes  = [
    {
      element: (
        <AuthGuardAdmin>
          <DashboardLayout /> 
          </AuthGuardAdmin>
       
      ),
      
      children: [
          ...AdminRoutes2,
      
      ],
  
  
  
    },
  
  ];
  
  

  export default AdminRoutes; 
  
  
