import { lazy } from "react";
import Suspense from "../../Suspense";
import useSettings from "../../hooks/useSettingsIde";

const layouts = {
  layout1: lazy(() => import("./Layout1/Layout1")),
};

const LayoutIde = (props) => {
  const { settings } = useSettings();
  const Layout = layouts[settings.activeLayout];

  return (
    <Suspense>
      <Layout {...props} /> 
    </Suspense>
  );
};

export default LayoutIde;   
