import { createContext, useState } from "react";
import { merge } from "lodash";
import { layoutSettings } from "./layouts/Admin/settings";

const SettingsContextAdmin = createContext({
  updateSettings: () => {},
  settings: layoutSettings,
});

export const SettingsProviderAdmin = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || layoutSettings);

  const handleUpdateSettings = (update = {}) => {
    const marged = merge({}, currentSettings, update);
    setCurrentSettings(marged);
  };

  return (
    <SettingsContextAdmin.Provider
      value={{ settings: currentSettings, updateSettings: handleUpdateSettings }} 
    >
      {children}
    </SettingsContextAdmin.Provider>
  );
};

export default SettingsContextAdmin; 
