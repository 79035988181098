import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userData, setUserData] = useState(false);

  const register = useCallback((data, token, expirationDate) => {
    setToken(token);
    setUserData(data);
    const tokenExpirationDate = expirationDate;
    setTokenExpirationDate(tokenExpirationDate);
    window.localStorage.setItem(
      'userData',
      JSON.stringify(data, token, expirationDate)
    );

    
  }, []);


  const login = useCallback((data, token, expirationDate) => {
    setToken(token);
    setUserData(data);
    const tokenExpirationDate = expirationDate;
    setTokenExpirationDate(tokenExpirationDate);
    window.localStorage.setItem(
      'userData',
      JSON.stringify({
        userData: data,
        token: token,
        expiration: tokenExpirationDate.toISOString()
      })
    );

  }, []);



  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserData(null);
    localStorage.removeItem('userData');  
    // localStorage.removeItem("user");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =  new Date(tokenExpirationDate).getTime() - new Date().getTime(); 
      logoutTimer = setTimeout(logout, remainingTime);
    } 
    else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(storedData.userData, storedData.token, new Date(storedData.expiration));
    }
  }, [login]);

  return { token, login, register, logout, userData };
};