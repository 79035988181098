import { lazy } from "react";
import Loadable from "../components/Loadable";
const LearningNetworkSim = Loadable(lazy(() => import("../pages/Learning/LearningNetworkSim")));
const LearningImageGenerator = Loadable(lazy(() => import("../pages/Learning/LearningImageGenerator")));
const LearningIoTRoutingViz = Loadable(lazy(() => import("../pages/Learning/LearningIoTRoutingViz")));


const NoDashboardRoutes = [
   
  { path: "/projects/cadsnode", element: <LearningNetworkSim /> }, 
  { path: "/projects/ai/image-generator", element: <LearningImageGenerator /> }, 
  { path: "/projects/iot/routing-visualizer", element: <LearningIoTRoutingViz /> }, 

];

export default NoDashboardRoutes;


