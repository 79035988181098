import { lazy } from "react";
import Loadable from "../components/Loadable";

const Learning = Loadable(lazy(() => import("../pages/Learning")));
const LearningPython = Loadable(lazy(() => import("../pages/Learning/LearningPython")));
const LearningIoTControlSetup = Loadable(lazy(() => import("../pages/Learning/LearningIoTControlSetup")));
const LearningDataRetrieval = Loadable(lazy(() => import("../pages/Learning/LearningDataRetrieval")));
const LearningGrammarCheck = Loadable(lazy(() => import("../pages/Learning/LearningGrammarCheck")));
const LearningBookWriter = Loadable(lazy(() => import("../pages/Learning/LearningBookWriter")));
const SingleCourseInfo = Loadable(lazy(() => import("../pages/Courses/SingleCourseInfo")));
const SignUpCourse = Loadable(lazy(() => import("../pages/Courses/SignUpCourse")));
const SingleCourse = Loadable(lazy(() => import("../pages/Courses/SingleCourse")));

const QuizLoading = Loadable(lazy(() => import("../pages/Courses/QuizPage/loading")));
const QuizPage = Loadable(lazy(() => import("../pages/Courses/QuizPage")));
const QuizLoadingArchived = Loadable(lazy(() => import("../pages/Courses/QuizPageArchived/loading")));
const QuizPageArchived = Loadable(lazy(() => import("../pages/Courses/QuizPageArchived")));

const ProjectLoading = Loadable(lazy(() => import("../pages/Courses/ProjectPage/loading")));
const ProjectPage = Loadable(lazy(() => import("../pages/Courses/ProjectPage")));
const ProjectLoadingArchived = Loadable(lazy(() => import("../pages/Courses/ProjectPageArchived/loading")));
const ProjectPageArchived = Loadable(lazy(() => import("../pages/Courses/ProjectPageArchived")));


const IdeRoutes2 = [
  { path: "/projects", element: <Learning /> }, 
  { path: "/projects/python", element: <LearningPython /> }, 
  { path: "/projects/iot/control-setup", element: <LearningIoTControlSetup /> }, 
  { path: "/projects/ai/data-knowledge-retrieval", element: <LearningDataRetrieval /> },
  { path: "/projects/ai/grammar-check", element: <LearningGrammarCheck /> },
  { path: "/projects/ai/book-writer", element: <LearningBookWriter /> },
  
  { path: "/course/info/:slug", element: <SingleCourseInfo /> },
  { path: "/sign-up/course/:slug", element: <SignUpCourse /> },
  { path: "/course/:slug", element: <SingleCourse /> },

  { path: "/quiz-loading/:quizId", element: <QuizLoading /> },
  { path: "/quiz/:quizId", element: <QuizPage /> },
  { path: "/quiz-loading/archived/:quizId", element: <QuizLoadingArchived /> },
  { path: "/quiz/archived/:quizId", element: <QuizPageArchived /> },

  { path: "/project-loading/:projectId", element: <ProjectLoading /> },
  { path: "/project/:projectId", element: <ProjectPage /> },
  { path: "/project-loading/archived/:projectId", element: <ProjectLoadingArchived /> },
  { path: "/project/archived/:projectId", element: <ProjectPageArchived /> },



];

export default IdeRoutes2;
