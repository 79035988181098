import DashboardLayout from "../contexts/layouts/Ide/LayoutIde";
import LearningRoutes2 from "./LearningRoutes2";



const LearningRoutes  = [
    {
      element: (
 
          <DashboardLayout /> 
          
       
      ),
      
      children: [
          ...LearningRoutes2,
      
      ],
  
  
  
    },
  
  ];
  
  

  export default LearningRoutes; 
  
  
