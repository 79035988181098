import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { searchReducer } from "./search";
import quizReducer from "./quizSlice";


const rootReducer = combineReducers({
  user: authReducer,
  search: searchReducer,
  quiz: quizReducer,
});

export default rootReducer;
