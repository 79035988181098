import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import App from "./App";
import { HelmetProvider } from 'react-helmet-async';

// third party css
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";



ReactDOM.render(
  <HelmetProvider>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  </HelmetProvider>,
  document.getElementById("root")
);
