import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";



const AuthGuardAdmin = ({ children }) => {
    let { user } = useSelector((state) => ({ ...state }));
    const { pathname } = useLocation();


    if (user && user.user.role.includes('admin')){
        return <>{children}</>
    }

    return <Navigate replace to="/" state={{ from: pathname }} />; 

    
};

export default AuthGuardAdmin;
