import { Navigate, useLocation } from "react-router-dom";

const Redirect = () => {
  let location = useLocation();

  const from = location.state?.from || "/";

  return <Navigate to={from} />;
};

export default Redirect; 
