import { lazy } from "react";
import Loadable from "../components/Loadable";

const Home = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));

const Store = Loadable(lazy(() => import("../pages/Store")));
const SingleStoreProduct = Loadable(lazy(() => import("../pages/Store/SingleProduct")));

const SampleEbook = Loadable(lazy(() => import("../pages/Store/SampleEbook")));

const Blog = Loadable(lazy(() => import("../pages/Blog")));
const SingleBlog = Loadable(lazy(() => import("../pages/Blog/SingleBlog")));

const StripeSuccess = Loadable(lazy(() => import("../pages/Stripe/success")));
const ProductSuccess = Loadable(lazy(() => import("../pages/Stripe/product-success")));

const StripeSuccessCourse = Loadable(lazy(() => import("../pages/Stripe/success-course")));
const CourseSuccess = Loadable(lazy(() => import("../pages/Stripe/course-success")));

const StripeSuccessFullCourse  = Loadable(lazy(() => import("../pages/Stripe/success-full-course")));
const CourseFullSuccess = Loadable(lazy(() => import("../pages/Stripe/course-full-success")));

const StripeSuccessLearningMaterial = Loadable(lazy(() => import("../pages/Stripe/success-learning-material")));
const CourseLearningMaterialSuccess  = Loadable(lazy(() => import("../pages/Stripe/course-learning-material-success")));

const StripeSuccessPlatform = Loadable(lazy(() => import("../pages/Stripe/success-platform")));

const PricingPage = Loadable(lazy(() => import("../pages/Pricing")));


const Services = Loadable(lazy(() => import("../pages/Services")));
const ServicesWebDev = Loadable(lazy(() => import("../pages/Services/ServicesWebDev")));
const ServicesWebDevAi = Loadable(lazy(() => import("../pages/Services/ServicesWebDev/aiIntegration")));
const ServicesMobileDev = Loadable(lazy(() => import("../pages/Services/ServicesMobileDev")));
const ServicesMobileDevAi = Loadable(lazy(() => import("../pages/Services/ServicesMobileDev/aiIntegration")));
const ServicesIotDev = Loadable(lazy(() => import("../pages/Services/ServicesIotDev")));
const ServicesIotDevAi = Loadable(lazy(() => import("../pages/Services/ServicesIotDev/aiIntegration")));
const ServicesOrder = Loadable(lazy(() => import("../pages/Services/Order")));

const Courses = Loadable(lazy(() => import("../pages/Courses")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/Policies/PrivacyPolicyIndex")));




const CustomerRoutes = [



  { path: "/", element: <Home /> }, 
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },

  { path: "/store", element: <Store /> },
  { path: "/store/:slug", element: <SingleStoreProduct /> },  

  { path: "/blog", element: <Blog /> },
  { path: "/blog/:slug", element: <SingleBlog /> },

  { path: "/stripe/success/:slug", element: <StripeSuccess /> },
  { path: "/product/success/:slug", element: <ProductSuccess /> },

  { path: "/stripe/success/course/:lessonId", element: <StripeSuccessCourse /> },
  { path: "/course/success/:lessonId", element: <CourseSuccess /> },

  { path: "/stripe/success/full-course/:courseId", element: <StripeSuccessFullCourse /> },
  { path: "/course/full-success/:courseId", element: <CourseFullSuccess /> },

  { path: "/stripe/success/learning-material/:learningMaterialId", element: <StripeSuccessLearningMaterial /> },
  { path: "/course/learning-material/success/:learningMaterialId", element: <CourseLearningMaterialSuccess /> },


  { path: "/stripe/success/platform", element: <StripeSuccessPlatform /> },

  { path: "/pricing", element: <PricingPage/> },

  { path: "/sample-ebook/:slug", element: <SampleEbook/> },

  { path: "/services", element: <Services /> },
  { path: "/services/web-development", element: <ServicesWebDev /> },
  { path: "/services/web-development/ai-integration", element: <ServicesWebDevAi /> },
  { path: "/services/mobile-development", element: <ServicesMobileDev /> },
  { path: "/services/mobile-development/ai-integration", element: <ServicesMobileDevAi /> },
  { path: "/services/iot-development", element: <ServicesIotDev /> },
  { path: "/services/iot-development/ai-integration", element: <ServicesIotDevAi /> },
  { path: "/services/order", element: <ServicesOrder /> },

  { path: "/courses", element: <Courses /> }, 
  { path: "/privacy-policy", element: <PrivacyPolicy /> }, 

  
  
];

export default CustomerRoutes;
